import { API, host } from 'api/utils'

const endpoint = `${host}/np`

export default class NovaposhtaAPI extends API {
    static async getCities(q) {
        const resp = await fetch(`${endpoint}/cities?q=${q}`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async getDepartments(cityRef, q) {
        const resp = await fetch(`${endpoint}/departments?ref=${cityRef}&q=${q}`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async getOwnerships() {
        const resp = await fetch(`${endpoint}/ownerships`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async getStreets(cityRef, q) {
        const resp = await fetch(`${endpoint}/streets?ref=${cityRef}&q=${q}`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }
}