export const paymentsOrder = {
    "en": [
        "PayPal", "Cash App", "Stripe", "Venmo", "Zelle", "Solid Gate", "MonoPay", "LiqPay", "Fondy", "Wayforpay", "Square", "Liberapay", "Flattr", "Buy Me a Coffee", "Patreon", "Ko-fi", 'Swish', 'Vipps', 'MobilePay', 'Bluecode', "Payconiq by Bancontact"
    ],
    "ua": [
        "MonoPay", "A-Bank", "LiqPay", "Fondy", "Wayforpay", "PayPal", "Cash App", "Stripe", "Venmo", "Zelle", "Solid Gate", "Square", "Liberapay", "Flattr", "Buy Me a Coffee", "Patreon", "Ko-fi", 'Swish', 'Vipps', 'MobilePay', 'Bluecode', "Payconiq by Bancontact"
    ]
}

export const paymentsList = [
    {
        name: "MonoPay",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(pay\.)?mbnk\.(app|biz)\/.+$', 'i').test(value)
    },
    {
        name: "A-Bank",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(pay\.)?a-bank\.com\.ua\/.+$', 'i').test(value)
    },
    {
        name: "PayPal",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.)?paypal\.(com|me)\/.+$', 'i').test(value)
    },
    {
        name: "Cash App",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/cash\.app\/.+$', 'i').test(value)
    },
    {
        name: "Stripe",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(checkout\.|buy\.)?stripe\.com\/.+$', 'i').test(value)
    },
    {
        name: "Venmo",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(account\.)?venmo\.com\/u\/.+$', 'i').test(value)
    },
    {
        name: "Zelle",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.|enroll\.)?zellepay\.com\/.+$', 'i').test(value)
    },
    {
        name: "Solid Gate",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(payment-page\.)?solidgate\.com\/.+$', 'i').test(value)
    },
    {
        name: "Fondy",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(pay\.|api\.)?fondy\.(eu|ua)\/.+$', 'i').test(value)
    },
    {
        name: "LiqPay",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.)?liqpay\.ua\/.+$', 'i').test(value)
    },
    {
        name: "Wayforpay",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(secure|i)?(\.)?(wayforpay|wtickets)\.(com|online)\/.+$', 'i').test(value)
    },
    {
        name: "Square",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(checkout\.)?(square|squareup)\.(site|com)\/.+$', 'i').test(value)
    },
    {
        name: "Liberapay",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(\.en|ru\.)?liberapay\.com\/.+$', 'i').test(value)
    },
    {
        name: "Flattr",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.)?flattr\.com\/.+$', 'i').test(value)
    },
    {
        name: "Buy Me a Coffee",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.)?buymeacoffee\.com\/.+$', 'i').test(value)
    },
    {
        name: "Patreon",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/(www\.)?patreon\.com\/.+$', 'i').test(value)
    },
    {
        name: "Ko-fi",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^https\:\/\/ko-fi\.com\/.+$', 'i').test(value)
    },
    {
        name: "Swish",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^http(s|)\:\/\/(www\.|app\.|mpc\.)swish\.nu\/.+$', 'i').test(value)
    },
    {
        name: "Vipps",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^http(s|)\:\/\/(qr\.|)vipps\.no\/.+$', 'i').test(value)
    },
    {
        name: "Bluecode",
        placeholder: "profile-payments__link_placeholder",
        validate: value => {
            return new RegExp('^http(|s):\/\/(go\.|sdk-selector\.)bluecode.com\/.+$').test(value)
        }
    },
    {
        name: "MobilePay",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^http(s|)\:\/\/(www\.|)mobilepay\.dk\/.+$', 'i').test(value)
    },
    {
        name: "Payconiq by Bancontact",
        placeholder: "profile-payments__link_placeholder",
        validate: value => new RegExp('^http(s|)\:\/\/(www\.|)payconiq\.(com|be)\/.+$', 'i').test(value)
    }
]