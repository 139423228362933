import AdminAPI from 'api/adminAPI'
import i18n from 'locale'
import { makeAutoObservable, toJS } from 'mobx'

// TODO: отрефакторить этот стор

const defaultCompany = Object.freeze({
    name: {
        value: '',
        warning: false,
    },
    domains: {
        value: [],
        warning: false,
    },
    logoLink: {
        value: '',
        warning: false,
    },
    logoFile: {
        value: null,
        warning: false,
    },
    backgroundFile: {
        value: null,
        warning: false,
    },
    lang: {
        value: '',
        warning: false,
    },
    footerText: {
        value: '',
        warning: false,
    },
    footerLink: {
        value: '',
        warning: false,
    },
    footerFile: {
        value: null,
        warning: false,
    },
    metaTitle: {
        value: '',
        warning: false,
    },
    metaDesc: {
        value: '',
        warning: false,
    },
    metaFile: {
        value: null,
        warning: false,
    },
    phonePrefix: {
        value: '',
        warning: false,
    },
    mailLogoFile: {
        value: null,
        warning: false,
    },
    mail: {
        from: {
            value: '',
            warning: false,
        },
        login: {
            value: '',
            warning: false,
        },
        password: {
            value: '',
            warning: false,
        },
        server: {
            value: '',
            warning: false,
        },
        port: {
            value: 465,
            warning: false,
        }
    },
    blocks: {
        banks: {
            value: true,
            warning: false,
        },
        waiterContacts: {
            value: true,
            warning: false,
        },
        messengers: {
            value: true,
            warning: false,
        },
        socialNetworks: {
            value: true,
            warning: false,
        },
        customization: {
            value: true,
            warning: false,
        },
        customButtons: {
            value: true,
            warning: false,
        },
        payments: {
            value: true,
            warning: false,
        },
    }
})

const defaultGroup = Object.freeze({
    name: {
        value: '',
        warning: false,
    },
    count: {
        value: 1,
        warning: false,
    },
    byTable: {
        value: 'false',
        checked: false,
    },
    notify: {
        value: '',
        checked: false,
    },
    file: {
        value: null,
        warning: false,
    },
    logoLink: {
        value: '',
        warning: false,
    },
    logoFile: {
        value: null,
        warning: false,
    },
    authLink: {
        value: '',
        checked: false,
    },
})

const defaultPush = Object.freeze({
    createdAt: {
        value: '',
        warning: false,
    },
    title: {
        value: '',
        warning: false,
    },
    body: {
        value: '',
        warning: false,
    },
    actions: {
        value: [{
            title: 'Open and see more',
            action: 'https://sea-pass.uk'
        }],
        warning: false,
    },
    imageFile: {
        value: null,
        warning: false,
    },
    iconFile: {
        value: null,
        warning: false,
    },
    badgeFile: {
        value: null,
        warning: false,
    }
})

const defaultOrder = Object.freeze({
    productID: '1',
    count: 1,
})
class AdminStore {
    loading = true
    users = null
    tokens = null
    messages = null
    selected = []
    tokensSelected = []
    messagesSelected = []
    selectedGroup = 'all'
    selectedCompany = 'all'
    createGroupModal = {
        opened: false,
        loading: false,
        group: copy(defaultGroup)
    }

    orderModal = {
        opened: false,
        userID: '',
        orders: [copy(defaultOrder)],
    }

    pushModal = {
        opened: false,
        loading: false,
        push: copy(defaultPush),
    }

    company = window.companyData

    companyListModal = {
        opened: false,
        list: []
    }

    companyModal = {
        opened: false,
        loading: false,
        company: copy(defaultCompany)
    }

    products = []

    loaded = false
    groups = []
    allGroups = []
    allUsers = null

    payStatuses = [
        {
            value: 10,
            label: '30 бесплатных дней',
        },
        {
            value: 20,
            label: 'Годовая подписка',
        },
        {
            value: 25,
            label: '∞ Подписка',
        },
        {
            value: 30,
            label: 'Истек срок',
        },
    ]
    orderStatuses = [
        {
            value: 10,
            label: 'Нет',
        },
        {
            value: 20,
            label: 'Есть',
        },
        {
            value: 30,
            label: 'Готов',
        },
    ]
    types = null

    constructor() {
        makeAutoObservable(this)
        this.types = i18n.t('user-types', { returnObjects: true })
    }

    fetchData = () => {
        this.fetchUsers()
        this.fetchPushes()
    }

    fetchUsers = () => {
        this.loading = true
        AdminAPI.getAllUsers().then(users => {
            if (users) {
                const groups = []
                users.forEach(user => user.group_name && !groups.includes(user.group_name) && groups.push(user.group_name))
                this.groups = groups
                this.allGroups = groups
                this.users = users.filter(u => !u.deleted_at)
                this.allUsers = users
            } else {
                this.users = []
                this.allUsers = []
            }

            this.loading = false
            this.loaded = true
        }).catch(e => window.notify('Ошибка при получении пользователей: ' + e.message, 'error'))

        AdminAPI.getDefaultCompany().then(c => (this.company = c)).catch(e => window.notify('Ошибка при получении компании: ' + e.message, 'error'))
    }

    fetchPushes = () => {
        AdminAPI.getAllPushTokens().then(tokens => (this.tokens = tokens)).catch(e => window.notify('Ошибка при получении токенов: ' + e.message, 'error'))

        AdminAPI.getAllPushMessages().then(messages => (this.messages = messages)).catch(e => window.notify('Ошибка при получении сообщений: ' + e.message, 'error'))
    }


    handleSelectGroup = group => {
        switch (group) {
            case 'add_new':
                this.createGroupModal.opened = true
                return

            case this.selectedGroup:
                return

            default:
                this.selectedGroup = group
                this.filterUsers()
                break
        }


    }

    filterUsers = () => {
        let users = toJS(this.allUsers)

        switch (this.selectedGroup) {
            case 'all': break

            case 'deleted':
                users = users.filter(u => u.deleted_at)
                break

            default:
                users = users.filter(u => u.group_name === this.selectedGroup)
                break
        }

        this.users = users
    }

    filterGroups = () => {
        if (this.selectedCompany === 'all') {
            this.groups = this.allGroups
            return
        }

        let groups = []
        this.users.forEach(user => {
            if (!user.group_name || groups.includes(user.group_name)) return

            let company = this.selectedCompany === 'passment' ? '' : this.selectedCompany
            if (user.companyID == company) {
                groups.push(user.group_name)
            }
        })

        this.groups = groups
    }

    createGroup = async () => {
        try {
            this.createGroupModal.loading = true

            await AdminAPI.createGroup(convertForm(this.createGroupModal.group, false))

            this.loaded = false
            this.fetchUsers()

            this.createGroupModal = {
                opened: false,
                loading: false,
                group: copy(defaultGroup)
            }

            this.createGroupModal.opened = false
            window.notify('Пул успешно создан', 'success')
        } catch (e) {
            window.notify('Ошибка при создании пула: ' + e.message, 'error')
        } finally {
            this.createGroupModal.loading = false
        }
    }

    deleteUsers = async ids => {
        if (!window.confirm(`Вы действительно хотите удалить ${ids.length} пользовател${ids.length > 4 ? 'ей' : 'я'}?\nЭто действие необратимо`)) {
            return
        }

        try {
            await AdminAPI.deleteUsers(ids)
            window.notify('Пользователи успешно удалены', 'success')
            this.selected = []
            this.users = this.users.filter(u => !ids.includes(u.id))
            this.allUsers = this.allUsers.filter(u => !ids.includes(u.id))
            this.groups = []
            this.allUsers.forEach(user => user.group_name && !this.groups.includes(user.group_name) && this.groups.push(user.group_name))
        } catch (e) {
            window.notify('Ошибка при удалении пользователей: ' + e.message, 'error')
        }
    }

    deletePushTokens = async tokens => {
        if (!window.confirm(`Вы действительно хотите удалить ${tokens.length} токен${tokens.length > 1 ? (tokens.length > 4 ? 'ов' : 'а') : ''}?\nЭто действие необратимо`)) {
            return
        }

        try {
            await AdminAPI.deletePushTokens(tokens)
            window.notify('Токены успешно удалены', 'success')
            this.tokensSelected = []
            this.tokens = this.tokens.filter(u => !tokens.includes(u.token))
        } catch (e) {
            window.notify('Ошибка при удалении токенов: ' + e.message, 'error')
        }
    }

    deletePushMessages = async messages => {
        if (!window.confirm(`Вы действительно хотите удалить ${messages.length} сообщени${messages.length > 1 ? (messages.length > 4 ? 'й' : 'я') : 'е'}?\nЭто действие необратимо`)) {
            return
        }

        try {
            await AdminAPI.deletePushMessages(messages)
            window.notify('Сообщения успешно удалены', 'success')
            this.messagesSelected = []
            this.messages = this.messages.filter(u => !messages.includes(u.id))
        } catch (e) {
            window.notify('Ошибка при удалении сообщений: ' + e.message, 'error')
        }
    }

    changeType = async (userID, type) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.type === type) return

        const name = this.types.find(t => t.value === type).label
        const confirmText = this.selected.length
            ? `Вы действительно хотите поменять тип выбранных профилей на "${name}"?`
            : `Вы действительно хотите поменять тип профиля "${user.name}" на "${name}"?`
        if (!window.confirm(confirmText)) {
            return
        }

        try {
            await AdminAPI.changeProfile(user.id, { type }, this.selected)
            user.type = type
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).type = type
            })
        } catch (e) {
            window.notify('Ошибка при изменении типа пользователя: ' + e.message, 'error')
        }
    }

    changePayStatus = async (userID, pay_status) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.pay_status === pay_status) return

        const name = this.payStatuses.find(t => t.value === pay_status).label
        const confirmText = this.selected.length
            ? `Вы действительно хотите поменять статус оплаты выбранных профилей на "${name}"?`
            : `Вы действительно хотите поменять статус оплаты профиля "${user.name}" на "${name}"?`
        if (!window.confirm(confirmText)) {
            return
        }

        try {
            await AdminAPI.changeProfile(user.id, { pay_status }, this.selected)
            user.pay_status = pay_status
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).pay_status = pay_status
            })
        } catch (e) {
            window.notify('Ошибка при изменении статуса оплаты: ' + e.message, 'error')
        }
    }

    changeOrderStatus = async (userID, order_status) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.order_status === order_status) return

        const name = this.orderStatuses.find(t => t.value === order_status).label
        const confirmText = this.selected.length
            ? `Вы действительно хотите поменять статус заказа выбранных профилей на "${name}"?`
            : `Вы действительно хотите поменять статус заказа профиля "${user.name}" на "${name}"?`
        if (!window.confirm(confirmText)) {
            return
        }

        try {
            await AdminAPI.changeProfile(user.id, { order_status }, this.selected)
            user.order_status = order_status
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).order_status = order_status
            })
        } catch (e) {
            window.notify('Ошибка при изменении статуса заказа: ' + e.message, 'error')
        }
    }

    openOrder = user => {
        this.orderModal = {
            opened: true,
            user: user,
            orders: [copy(defaultOrder)],
        }
    }

    addOrder = () => {
        this.orderModal.orders.push(copy(defaultOrder))
    }

    createOrder = async () => {
        try {
            const items = i18n.t('card-items', { returnObjects: true })
            await AdminAPI.createOrder(
                this.orderModal.user.id,
                this.orderModal.orders.map(o => {
                    o.productName = items.find(p => p.id === o.productID).title
                    return o
                }),
            )
            this.orderModal.opened = false
            if (this.orderModal.user.orders) this.orderModal.user.orders.push(...this.orderModal.orders)
            else this.orderModal.user.orders = this.orderModal.orders
            window.notify('Заказы успешно добавлены', 'success')
        } catch (e) {
            window.notify('Ошибка при добавлении заказов: ' + e.message, 'error')
        }
    }

    toggleCustomization = async (userID, enabled) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.customization_enabled === enabled) return

        const confirmText = this.selected.length
            ? `Вы действительно хотите ${enabled ? 'включить ' : 'выключить '} персонализацию у выбранных профилей?`
            : `Вы действительно хотите ${enabled ? 'включить ' : 'выключить '} персонализацию у профиля "${user.name}"?`
        if (!window.confirm(confirmText)) {
            return
        }

        try {
            await AdminAPI.changeProfile(user.id, { customization_enabled: enabled }, this.selected)
            user.customization_enabled = enabled
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).customization_enabled = enabled
            })
        } catch (e) {
            window.notify('Ошибка при изменении профиля: ' + e.message, 'error')
        }
    }

    toggleCreatedBy = async (userID, disabled) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.customization.disable_created_by === disabled) return

        const confirmText = this.selected.length
            ? `Вы действительно хотите ${!disabled ? 'включить ' : 'выключить '} лого "created by" у выбранных профилей?`
            : `Вы действительно хотите ${!disabled ? 'включить ' : 'выключить '} лого "created by" у профиля "${user.name}"?`
        if (!window.confirm(confirmText)) {
            return
        }

        try {
            await AdminAPI.changeProfile(user.id, { "customization.disable_created_by": disabled }, this.selected)
            user.customization.disable_created_by = disabled
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).customization.disable_created_by = disabled
            })
        } catch (e) {
            window.notify('Ошибка при изменении профиля: ' + e.message, 'error')
        }
    }

    toggleEmailBinding = async (userID, value) => {
        const user = this.users.find(({ id }) => userID === id)
        if (user.email_binding_disabled === value) return

        let selectedUsers = this.selected.map(id => this.users.find(u => u.id === id)).filter(u => u.need_registration)
        const selected = selectedUsers.map(u => u.id)

        try {
            await AdminAPI.changeProfile(user.id, { email_binding_disabled: value }, selected)
            user.email_binding_disabled = value
            selected.forEach(id => {
                this.users.find(u => u.id === id).email_binding_disabled = value
            })
        } catch (e) {
            window.notify('Ошибка при изменении профиля: ' + e.message, 'error')
        }
    }

    copyCustomization = async (donorID) => {
        if (!this.selected.length) return

        const donor = this.users.find(({ id }) => donorID === id)
        if (!window.confirm(`Вы действительно хотите скопировать персонализацию профиля "${donor.name}" на выбранные профили?`)) {
            return
        }

        try {
            await AdminAPI.copyCustomization(donor.id, this.selected.filter(id => id !== donor.id))
            this.selected.forEach(id => {
                this.users.find(u => u.id === id).customization = toJS(donor.customization)
            })
        } catch (e) {
            window.notify('Ошибка при изменении профиля: ' + e.message, 'error')
        }
    }

    restoreUser = async (userID) => {
        const user = this.users.find(({ id }) => userID === id)
        if (!user.deleted_at) return

        if (!window.confirm(`Вы действительно хотите восстановить аккаунт "${user.name}"?`)) {
            return
        }

        try {
            await AdminAPI.restoreUser(user.id)
            user.deleted_at = null
        } catch (e) {
            window.notify('Ошибка при восстановлении аккаунта: ' + e.message, 'error')
        }
    }

    openCompany = () => {
        this.companyModal = {
            opened: true,
            loading: false,
            company: Object.assign({}, defaultCompany, convertCompany(this.company, true))
        }
    }

    togglePush = message => {
        if (message) {
            const push = convertForm(message, true, [], [])
            this.pushModal.push = Object.assign({}, defaultPush, push)
        } else {
            this.pushModal.push = Object.assign({}, defaultPush)
        }
        this.pushModal.opened = !this.pushModal.opened
    }

    sendPushAll = async () => {
        try {
            const createdAt = this.pushModal.push.createdAt
            const push = convertForm(this.pushModal.push, false, [], [])
            const newPush = await AdminAPI.sendPushAll(push)
            if (createdAt.value != newPush.createdAt) {
                this.messages.push(newPush)
            }
            this.togglePush()
            window.notify('Сообщение успешно отправлено', 'success')
        } catch (e) {
            window.notify('Ошибка при отправке пуша: ' + e.message, 'error')
        }
    }

    saveCompany = async () => {
        try {
            this.companyModal.loading = true

            const company = await AdminAPI.saveCompany(convertCompany(this.companyModal.company, false))

            this.company = company

            this.companyModal.opened = false
            window.notify('Компания успешно сохранена', 'success')
        } catch (e) {
            window.notify('Ошибка при сохранении компании: ' + e.message, 'error')
        }

        this.companyModal.loading = false
    }

    deleteCompany = async company => {
        if (!window.confirm(`Вы действительно хотите удалить компанию "${company.name}"?`)) {
            return
        }

        try {
            await AdminAPI.deleteCompany(company.id)

            this.company = {}

            window.notify('Компания успешно удалена', 'success')
        } catch (e) {
            window.notify('Ошибка при удалении компании: ' + e.message, 'error')
        }
    }

    setValue = (obj, value) => {
        obj.value = value
    }
}

function copy(obj) {
    return Object.assign({}, obj)
}


function convertCompany(comp, toForm) {
    if (!comp) return {}

    const exclude = ['mail']
    if (toForm) {
        exclude.push(...['logoFile', 'backgroundFile', 'footerFile', 'mailLogoFile', 'metaFile'])
    }

    const res = convertForm(comp, toForm, [], exclude)
    res.mail = convertForm(comp.mail, toForm)
    res.blocks = convertForm(comp.blocks, toForm)
    return res
}

function convertForm(obj, toForm, onlyValue = [], exclude = []) {
    const res = {}

    obj && Object.keys(obj).forEach(key => {
        if (exclude.includes(key)) return

        if (onlyValue.includes(key)) {
            res[key] = obj[key].value ? obj[key].value : obj[key]
            return
        }

        if (toForm) {
            res[key] = {
                value: obj[key],
                warning: false
            }
        } else {
            res[key] = obj[key].value
        }
    })

    return res
}

export const adminStore = new AdminStore()