import { API, host } from 'api/utils'

const endpoint = `${host}/products`

// TODO: refactor
export default class ProductsAPI extends API {
    static async getProductsBase() {
        const resp = await fetch(`${endpoint}/base`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) {
            this.products = await resp.json()
        }

        return this.products
    }

    static async getProducts(accessoryID) {
        const resp = await fetch(`${endpoint}/?accessory_id=${accessoryID}`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })

        if (await this._checkStatus(resp)) {
            this.products = await resp.json()
        }

        return this.products
    }
}