import { getCheckURLRegexp, validatePhone } from "./validations";

export const messengersList = [
    {
        name: "Telegram",
        placeholder: "profile-messengers__telegram_placeholder",
        validate: value => new RegExp('^(https\:\/\/t\.me\/)?[0-9a-z_]{2,64}$', 'i').test(value)
    },
    {
        name: "WhatsApp",
        placeholder: "profile-messengers__whatsapp_placeholder",
        validate: value => {

            if (value.includes('whatsapp.com/channel/')) {
                return value.replace(/http(|s)(:\/\/|)(www\.|)whatsapp\.com\/channel\//i, '').length !== 0;
            }

            if (value.includes('wa.me')) {
                return new RegExp('^(https:\\/\\/)wa\\.me\\/([0-9]){2,50}$', 'i').test(value)
            }

            const result = validatePhone(value)
            return typeof (result) === 'string' ? false : result
        }
    },
    {
        name: "Viber",
        placeholder: "profile-messengers__viber_placeholder",
        validate: value => {
            if (value.includes('invite.viber.com')) {
                return getCheckURLRegexp("invite\\.viber\\.com").test(value)
            }

            const result = validatePhone(value)
            return typeof (result) === 'string' ? false : result
        }
    },
    {
        name: "Discord",
        placeholder: 'profile-messengers__link_placeholder',
        validate: value => new RegExp('^(https:\\/\\/)discord\\.(gg|com)\\/(invite\\/)?([0-9A-Za-z]){2,64}', 'i').test(value)
    },
    {
        name: "Messenger",
        placeholder: "profile-messengers__messenger_placeholder",
        validate: value => {
            if (value.includes("messenger.com")) {
                return new RegExp('^https:\\/\\/(www\\.)?messenger\\.com\\/t\\/[0-9a-zA-Z\\.]{5,50}$', 'i').test(value)
            }

            if (value.includes('m.me')) {
                return new RegExp('^https?:\\/\\/(www\\.)?m\\.me\\/[0-9a-zA-Z\\.]{5,50}(\\?ref\\=.*)*$', 'i').test(value)
            }

            return new RegExp('^[0-9a-zA-Z\\.]{5,50}$', 'i').test(value)
        }
    },
    {
        name: "Skype",
        placeholder: "profile-messengers__skype_placeholder",
        validate: value => {
            if (value.includes('join.skype.com')) {
                return new RegExp('^https?:\\/\\/join\\.skype\\.com\\/(invite\\/)*[0-9a-zA-Z]{5,50}$', 'i').test(value)
            }

            return new RegExp('^(live\\:)?[a-z][a-z0-9\\.,\\-_]{5,31}$', 'i').test(value)
        }
    },
    {
        name: "Snapchat",
        placeholder: "profile-messengers__snapchat_placeholder",
        validate: value => new RegExp('^(https:\\/\\/www\\.snapchat\\.com\\/add\\/)?[a-z][a-z0-9\\.\\-_]{3,15}$', 'i').test(value)
    },
    {
        name: "Zoom",
        placeholder: "profile-messengers__zoom_placeholder",
        validate: value => new RegExp('^(https:\\/\\/)([a-z0-9]+\\.)?zoom\\.[a-z]{2,}\\/[a-z0-9]+\\/[a-z0-9]+\\?pwd\\=[0-9A-Za-z]+', 'i').test(value)
    }
]