import {
    checkUser,
    host
} from 'api/utils'
import Cookies from 'js-cookie'  

const endpoint = `${host}/auth` 

export default class AuthAPI {
    static loginListeners = []

    static subscribeLogin(f) {
        this.loginListeners.push(f)
    }

    static unsubscribeLogin(f) {
        const index = this.loginListeners.findIndex(l => l === f)
        if(index >= 0) this.loginListeners.splice(index, 1)
    }

    static async checkUser() {
        return await checkUser()
    } 

    static async signOut() {
        const resp = await fetch(`${endpoint}/signout`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow'
        })
    
        switch(resp.status) {
            case 200:
                Cookies.remove('Token')
                this.loginListeners.forEach(l => l(false))
                return
    
            default:
                throw new Error("error__unknown")
        }
    }

    static async signIn(payload) {
        const resp = await fetch(`${endpoint}/signin`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(payload)
        })
    
        switch(resp.status) {
            case 200:
                this.loginListeners.forEach(l => l(true))
                return
    
            case 401:
                throw new Error("signin-error__wrong-credentials")
    
            default:
                throw new Error("error__unknown")
        }
    }

    static async restorePassword(payload) {
        const resp = await fetch(`${endpoint}/restore`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(payload)
        })
    
        switch(resp.status) {
            case 200:
                return
    
            case 401:
                throw new Error("error__user-not-exists")
    
            default:
                throw new Error("error__unknown")
        }
    }

    static async signUp(payload) {
        const resp = await fetch(`${endpoint}/signup`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(payload)
        })
    
        switch(resp.status) {
            case 200:
                this.loginListeners.forEach(l => l(true))
                return
    
            case 409:
                throw new Error("error__user-already-exists")
    
            default:
                throw new Error("error__unknown")
        }
    }
}