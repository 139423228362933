import AuthAPI from 'api/auth'
import ProfileAPI from 'api/profile'
import equal from 'fast-deep-equal'
import i18n from 'i18next'
import { makeAutoObservable, reaction, toJS, transaction } from 'mobx'
import { sortContacts, updateLayout } from 'utils/layout'
import serviceParser from 'utils/serviceParser.ts'
import { checkLimit, newValuePlaceholder, validateBank, validateCustomButton, validateCustomization, validateEmail, validateGroupLogo, validateMessenger, validateName, validatePayment, validatePhone, validateService, validateSite } from 'utils/validations'

const autoSaveDelayMs = 2000
const defaultWarnings = {
    phones: {},
    emails: {},
    sites: {},
    banks: {},
    social_networks: {},
    messengers: {},
    waiter_messengers: {},
    custom_buttons: {},
    customization: {},
    payments: {},
    group_logo: {},
}

class ProfileStore {
    isLoading = false
    profile = null
    warnings = Object.assign({}, defaultWarnings)
    products = []

    changes = {}
    saveTimeoutID = null
    reactions = []
    loaded = false

    contacts = []
    prevContacts = []
    prevLayout = []
    dndActive = false

    constructor() {
        makeAutoObservable(this)
        AuthAPI.subscribeLogin(login => !login && this.reset())
    }

    reset = () => {
        this.unregisterReactions()
        this.loaded = false
        this.profile = null
    }

    load = fresh => {
        if ((this.isLoading || this.loaded) && !fresh) return

        this.isLoading = true

        this.updateProfile()
    }

    updateProfile = async () => {
        try {
            console.debug('Loading profile')
            const profile = await ProfileAPI.getProfile()
            this.unregisterReactions()
            console.debug('Profile loaded', profile)

            profile.orders =
                profile.orders &&
                profile.orders.map(o => {
                    o.price = o.count * this.products.find(p => p.id === o.productID).price
                    return o
                })

            console.debug('Prepare profile')
            this.prepareProfile(profile)

            transaction(() => {
                this.contacts = []
                this.prevContacts = []
                this.prevLayout = []
                this.changes = {}
                this.warnings = Object.assign({}, defaultWarnings)
                this.profile = profile
                this.isLoading = false
                this.loaded = true
            })

            console.debug('Registering reactions')
            this.registerReactions()

            console.debug('Preparing layout')
            this.prepareLayout(true)
            console.debug('Layout prepared')
        }
        catch (e) {
            console.error(e)
            await AuthAPI.signOut()
        }
    }

    prepareProfile(profile) {
        profile.social_networks = profile.social_networks || []
        profile.social_networks.forEach(element => {
            if (element.name) return
            const service = serviceParser(element.value)
            element.name = service
        });
    }

    prepareLayout(force = false) {
        const contacts = sortContacts(this.profile, this.warnings)

        if (!force && this.contacts.length === contacts.length) {
            return
        }

        transaction(() => {
            this.contacts = contacts
            this.profile.layout = updateLayout(toJS(this.contacts), toJS(this.profile.layout), this.prevLayout)
            this.prevLayout = toJS(this.profile.layout)
        })
    }

    unregisterReactions() {
        this.reactions.forEach(r => r())
        this.reactions = []
    }

    registerReactions() {
        const validateSingle = (field, value, validate) => {
            const validateResult = validate(value)
            if (validateResult === true) {
                this.warnings[field] = false
                return true
            } else {
                this.changes[field] && delete this.changes[field]
                this.warnings[field] = validateResult
                return false
            }
        }

        const validateArray = (field, arr, validate) => {
            const warnings = {}
            let isAllValid = true
            arr.forEach((value, i) => {
                const validateResult = validate(value, arr, field)
                if (validateResult === true) {
                    warnings[i] = false
                } else {
                    this.changes[field] && this.changes[field][i] && this.changes[field].splice(i, 1)

                    warnings[i] = validateResult === false ? true : validateResult

                    isAllValid = false
                }
            })

            this.warnings = Object.assign({}, this.warnings, { [field]: warnings })

            return isAllValid
        }

        const prepare = this.prepareLayout.bind(this)

        const reactionsData = [
            {
                validate: (field, value) => validateSingle(field, value, validateName),
                fields: ['name'],
            },
            {
                validate: () => true,
                fields: ['info'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validatePhone),
                fields: ['phones', 'waiter_phones'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateEmail),
                fields: ['emails'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateService),
                fields: ['social_networks'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateBank),
                fields: ['banks'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateMessenger),
                fields: ['messengers', 'waiter_messengers'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validatePayment),
                fields: ['payments'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateSite),
                fields: ['sites'],
            },
            {
                prepare,
                validate: (field, value) => validateArray(field, value, validateCustomButton),
                fields: ['custom_buttons'],
            },
            {
                validate: (field, value) => validateSingle(field, value, validateCustomization),
                fields: ['customization'],
            },
            {
                validate: (field, value) => validateSingle(field, value, validateGroupLogo),
                fields: ['group_logo'],
            },
            {
                validate: () => true,
                fields: ['hide_profile_photo', 'hide_logo', 'dnd_enabled', 'card_items'],
            },
            {
                prepare: () => this.prepareLayout(true),
                validate: () => true,
                fields: ['layout'],
            }
        ]

        const reactions = []

        reactionsData.forEach(({ prepare, validate, fields }) => {
            fields.forEach(field => {
                reactions.push(
                    reaction(
                        () => toJS(this.profile[field]),
                        (value, prev) => {
                            const eq = equal(value, prev)
                            if (!eq && validate(field, value) === true) {
                                prepare && prepare()
                                this.addChanges(field, value)
                            }
                        },
                    ),
                )
            })
        })

        this.reactions = reactions
    }

    async addChanges(field, value) {
        Object.assign(this.changes, { [field]: value })
        if (!Object.keys(this.changes).length) return

        console.debug('changes', this.changes)

        const { disable_auto_save } = this.profile

        if (disable_auto_save) {
            const cancel = () => {
                this.changes = {}
                this.updateProfile()
            }

            const save = () => this.saveChanges()

            window.saveNotify(i18n.t('save-notify__text'), save, cancel)
            return
        }

        if (this.saveTimeoutID) {
            clearTimeout(this.saveTimeoutID)
        }

        this.saveTimeoutID = setTimeout(() => {
            this.saveChanges()
            this.saveTimeoutID = null
        }, autoSaveDelayMs)
    }

    async saveChanges() {
        if (!Object.keys(this.changes).length) return
        console.debug('Saving changes', this.changes)
        this.save(this.changes)
        this.changes = {}
    }

    async save(changes) {
        return await this.apiSaveCall(async () => {
            return await ProfileAPI.changeProfile(changes)
        }, i18n.t('save-notify__error'))
    }

    saveLink = async url => {
        const oldURL = this.profile.custom_url ? this.profile.custom_url : this.profile.url

        if (oldURL !== url) {
            const res = await this.apiSaveCall(async () => {
                return await ProfileAPI.changeProfileURL(url)
            }, i18n.t('save-notify__url_error'), true)

            if (res) {
                this.profile.custom_url = oldURL
            }
        }
    }

    saveMenuLink = async link => {
        return await this.apiSaveCall(async () => {
            const res = await ProfileAPI.setProfileMenuLink(link)
            this.profile.menu = link
            this.profile.is_menu_uploaded = false
            return res
        }, i18n.t('save-notify__menu_error'))
    }

    deleteMenuLink = async () => {
        if (await this.save({ menu: '', is_menu_uploaded: false })) {
            this.profile.menu = ''
            this.profile.is_menu_uploaded = false
        }
    }

    async uploadPhoto(img) {
        const oldPhoto = this.profile.photo
        try {
            this.profile.photo = img
            await this.apiSaveCall(async () => {
                this.profile.photo = (await ProfileAPI.uploadProfilePhoto(img)) + '?' + new Date().getTime()
            }, i18n.t('save-notify__photo_error'), true)
        } catch (err) {
            this.profile.photo = oldPhoto
        }
    }

    async uploadCustomLogo(file) {
        await this.apiSaveCall(async () => {
            this.profile.customization.logo = (await ProfileAPI.uploadProfileCustomLogo(file)) + '?' + new Date().getTime()
        }, i18n.t('save-notify__custom-logo_error'))
    }

    async deleteCustomLogo() {
        await this.apiSaveCall(async () => {
            await ProfileAPI.deleteProfileCustomLogo()
            this.profile.customization.logo = ''
        }, i18n.t('save-notify__custom-logo_delete_error'))
    }

    async uploadGroupLogo(file) {
        await this.apiSaveCall(async () => {
            this.profile.group_logo.img = (await ProfileAPI.uploadGroupLogo(file)) + '?' + new Date().getTime()
        }, i18n.t('save-notify__custom-logo_error'))
    }

    async deleteGroupLogo() {
        await this.apiSaveCall(async () => {
            await ProfileAPI.deleteGroupLogo()
            this.profile.group_logo.img = ''
        }, i18n.t('save-notify__custom-logo_delete_error'))
    }

    async uploadCustomBackground(file) {
        await this.apiSaveCall(async () => {
            this.profile.customization.background = (await ProfileAPI.uploadProfileCustomBackground(file)) + '?' + new Date().getTime()
        }, i18n.t('save-notify__custom-background_error'))
    }

    async deleteCustomBackground() {
        await this.apiSaveCall(async () => {
            await ProfileAPI.deleteProfileCustomBackground()
            this.profile.customization.background = ''
        }, i18n.t('save-notify__custom-background_delete_error'))
    }

    async deletePhoto() {
        await this.apiSaveCall(async () => {
            await ProfileAPI.deleteProfilePhoto()
            this.profile.photo = ''
        }, i18n.t('save-notify__photo_delete_error'))
    }

    async uploadMenu(file) {
        await this.apiSaveCall(async () => {
            this.profile.menu = await ProfileAPI.uploadProfileMenu(file)
            this.profile.is_menu_uploaded = true
        }, i18n.t('save-notify__menu_error'))
    }

    async deleteMenu() {
        await this.apiSaveCall(async () => {
            await ProfileAPI.deleteProfileMenu()
            this.profile.menu = ''
            this.profile.is_menu_uploaded = false
        }, i18n.t('save-notify__menu_delete_error'))
    }

    async restoreProfile() {
        try {
            await ProfileAPI.restoreProfile()
            window.location.reload()
        } catch (err) {
            // @ts-ignore
            window.notify(`${i18n.t('restore-profile__error')}: ${i18n.t(err.message)}`, 'error')
        }
    }

    async restoreClearProfile() {
        try {
            await ProfileAPI.restoreClearProfile()
            window.location.reload()
        } catch (err) {
            // @ts-ignore
            window.notify(`${i18n.t('restore-profile__error')}: ${i18n.t(err.message)}`, 'error')
        }
    }

    async hardDeleteProfile() {
        try {
            await ProfileAPI.hardDeleteProfile()
            // @ts-ignore
            window.notify(`${i18n.t('delete-profile__notification')}`, 'info')
        } catch (err) {
            // @ts-ignore
            window.notify(`${i18n.t('delete-profile__error')}: ${i18n.t(err.message)}`, 'error')
        }
    }

    addNewValue(key, data) {
        if (!this.profile[key]) {
            this.profile[key] = []
        }

        if (!checkLimit(key, this.profile[key], data?.name)) return

        if (!data) data = {}

        this.profile[key].push({
            value: newValuePlaceholder,
            type: key,
            ...data
        });
    }

    setSetting(key, value) {
        this.profile[key] = value
    }

    // refactor this
    async apiSaveCall(func, errorText, throwOnError = false) {
        try {
            const res = await func()
            // @ts-ignore
            window.notify(i18n.t('save-notify__success'), 'success')
            return res
        } catch (err) {
            // @ts-ignore
            window.notify(`${errorText}: ${i18n.t(err.message)}`, 'error')
            if (throwOnError) {
                throw err
            }
        }
    }
}

export const profileStore = new ProfileStore()