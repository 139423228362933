import { UnauthorizedError } from './errors';

export const host = window.origin
//'/api'
export const originalHost = "https://passment.com.ua"

// TODO: write a fetch function with retries and checks

export async function checkUser() {
    return document.cookie.indexOf("Token=") > -1;
}

export function checkStatus(status) {
    switch (status) {
        case 200:
            return true

        case 401:
            throw new Error("signin-error__wrong-credentials")

        default:
            throw new Error("error__unknown")
    }
}

export class API {
    static async _checkAuth() {
        if (!await checkUser()) {
            const err = new UnauthorizedError()

            if (this._onError) this._onError(err)

            throw err
        }

        return true
    }

    static onError(f) {
        this._onError = f
    }

    static async _checkStatus(resp) {
        let err;

        switch (resp.status) {
            case 200:
                return true

            case 401:
                err = new UnauthorizedError()
                break

            default:
                let text = ''
                try {
                    text = await resp.text()
                } catch { }
                if (!text) {
                    text = "error__unknown"
                }
                err = new Error(text)
        }

        if (this._onError) this._onError(err)

        throw err
    }
}