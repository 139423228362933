import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import CustomDetector from './detector'
import ua from '../languages/ua.json';
import en from '../languages/en.json';

const detector = new LanguageDetector()
detector.addDetector(new CustomDetector())

export const languages = ['en', 'ua']

i18n.use(HttpApi)
    // @ts-ignore
    .use(initReactI18next)
    .use(detector)
    .init({
        resources: {
            en: { translation: en },
            ua: { translation: ua }
        },
        fallbackLng: 'en',
        supportedLngs: languages,

        nsSeparator: false,
        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },

        detection: {
            order: ['cookie', 'custom'],
            lookupCookie: 'Lang',
            cookieOptions: { sameSite: 'strict' },
            caches: ['cookie'],
        },
    })

export default i18n
