import {
    host
} from 'api/utils'
import download from 'downloadjs'
import Cookies from 'js-cookie'

const endpoint = `${host}/admin`
const apiEndpoint = `${endpoint}/api/v1`

export default class AdminAPI {
    static loginListeners = []

    static subscribeLogin(f) {
        this.loginListeners.push(f)
    }

    static unsubscribeLogin(f) {
        const index = this.loginListeners.findIndex(l => l === f)
        if (index >= 0) this.loginListeners.splice(index, 1)
    }

    static async checkUser() {
        return Boolean(Cookies.get('AdminToken'))
    }

    static async signIn(payload) {
        const resp = await fetch(`${endpoint}/auth/signin`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(payload)
        })

        switch (resp.status) {
            case 200:
                this.loginListeners.forEach(l => l(true))
                return

            case 401:
                throw new Error("Неверный логин или пароль")

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async signOut() {
        const resp = await fetch(`${endpoint}/auth/signout`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                Cookies.remove("AdminToken")
                this.loginListeners.forEach(l => l(false))
                return

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async getAllUsers() {
        const resp = await fetch(`${apiEndpoint}/users/`, {
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async createGroup(group) {
        var body = new FormData()
        Object.keys(group).forEach(key => body.append(key, group[key]))

        const resp = await fetch(`${apiEndpoint}/create-group`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body
        })

        switch (resp.status) {
            case 200:
                const result = await resp.blob()
                download(result, group.name + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                return

            case 409:
                throw new Error("Один из введенных email уже существует")

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async deleteUsers(ids) {
        const resp = await fetch(`${apiEndpoint}/users/`, {
            method: 'DELETE',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(ids)
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async changeProfile(id, changes, selected) {
        const resp = await fetch(`${apiEndpoint}/users/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify({
                selected: selected,
                changes: changes
            })
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async copyCustomization(donorID, recipientsIds) {
        const resp = await fetch(`${apiEndpoint}/users/${donorID}/copy-customization`, {
            method: 'PATCH',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(recipientsIds)
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async createOrder(id, orders) {
        const resp = await fetch(`${apiEndpoint}/users/${id}/order`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(orders)
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async restoreUser(id) {
        const resp = await fetch(`${apiEndpoint}/users/${id}/restore`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async getCompanies() {
        const resp = await fetch(`${apiEndpoint}/companies`, {
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async getDefaultCompany() {
        const resp = await fetch(`${apiEndpoint}/companies/default`, {
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async saveCompany(company) {
        var body = new FormData()
        Object.keys(company).forEach(key => {
            if (key.endsWith('File')) {
                body.append(key, company[key])
                delete company[key]
            }
        })

        body.append('data', JSON.stringify(company))

        const resp = await fetch(`${apiEndpoint}/companies`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                const err = await resp.text()
                if (err) {
                    throw new Error(err)
                }
                throw new Error("Неизвестная ошибка")
        }
    }

    static async deleteCompany(id) {
        const resp = await fetch(`${apiEndpoint}/companies/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return true

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async sendPushAll(push) {
        var body = new FormData()
        Object.keys(push).forEach(key => key !== 'actions' && body.append(key, push[key]))
        body.append('actions', JSON.stringify(push.actions))

        const resp = await fetch(`${apiEndpoint}/send-push/all`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async getAllPushTokens() {
        const resp = await fetch(`${apiEndpoint}/push/tokens`, {
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                return await resp.json()

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async deletePushTokens(tokens) {
        const resp = await fetch(`${apiEndpoint}/push/tokens/delete`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(tokens)
        })

        switch (resp.status) {
            case 200:
                return

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async getAllPushMessages() {
        const resp = await fetch(`${apiEndpoint}/push/messages`, {
            credentials: 'include',
            redirect: 'follow'
        })

        switch (resp.status) {
            case 200:
                let res = await resp.json()
                if (!res) return []
                return res

            default:
                throw new Error("Неизвестная ошибка")
        }
    }

    static async deletePushMessages(ids) {
        const resp = await fetch(`${apiEndpoint}/push/messages/delete`, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(ids)
        })

        switch (resp.status) {
            case 200:
                return

            default:
                throw new Error("Неизвестная ошибка")
        }
    }
}