const localeMap = Object.freeze({
    '^en.*': 'en',
    '^(ru|kk|ka|ro|hy|be|mo|uk).*': 'ua',
})

export default class CustomDetector {
    name = 'custom'

    lookup(options) {
        let found = []

        if (typeof navigator !== 'undefined') {
            if (navigator.languages) {
                // chrome only; not an array, so can't use .push.apply instead of iterating
                for (let i = 0; i < navigator.languages.length; i++) {
                    found.push(navigator.languages[i])
                }
            }

            if (navigator.userLanguage) {
                found.push(navigator.userLanguage)
            }

            if (navigator.language) {
                found.push(navigator.language)
            }
        }

        found = found.map(loc => {
            let formatted = ''
            const ok = Object.keys(localeMap).some(regex => {
                const r = new RegExp(regex)
                if (r.test(loc)) {
                    formatted = localeMap[regex]
                    return true
                }
                return false
            })

            if (ok) return formatted
            return 'en'
        })

        return found.length > 0 ? found : undefined
    }
}
