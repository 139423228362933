import { useTranslation } from "react-i18next"

export function isLegal(value) {
    return value === 'legal'
}

export function hasRibbon(accessoryID) {
    return accessoryID === 3
}

export function isNovaPoshta(value) {
    return value === "novaposhta"
}

export function uaLangValidation(value) {
    return /^([\d'А-яЕеЇїІіЄєҐґ№-][^ЫыЪъЭэЁё]*)*$/.test(value)
}

export function numberValidation(value) {
    return /^\d*$/.test(value)
}

export function isAddressShipping(value) {
    return value === 'address'
}

export function useCurrency(letters = false) {
    const { i18n } = useTranslation()

    return getCurrency(i18n.language, letters)
}

export function getCurrency(lang, letters = false) {
    switch (lang) {
        case 'en':
            return letters ? 'USD' : '$'
        case 'ua':
            return letters ? 'UAH' : '₴'
        default:
            return letters ? 'UAH' : '₴'
    }
}

export function round(value) {
    return Math.round(value * 100) / 100
}