import { API, host } from "api/utils";

const endpoint = `${host}/api/v1`;

export default class ProfileAPI extends API {
    static async pay(id) {
        window.open(`${endpoint}/profile/pay/${id}`);
    }

    static async getProfileByID(id, companyCard) {
        const resp = await fetch(`${host}${companyCard ? '/card' : ''}/profile/${id}`, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async getProfile() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/`, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async changeProfile(payload) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/`, {
            method: "PATCH",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async changeProfileURL(url) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/url`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ custom_url: url }),
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async changeProfileLayout(layout) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/layout`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(layout),
        });

        await this._checkStatus(resp);
    }

    static async uploadProfilePhoto(pfp) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/photo`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ pfp }),
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async uploadProfileCustomLogo(file) {
        if (!(await this._checkAuth())) return;

        var data = new FormData();
        data.append("file", file);

        const resp = await fetch(`${endpoint}/profile/custom-logo`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            body: data,
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async deleteProfileCustomLogo() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/custom-logo`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async uploadGroupLogo(file) {
        if (!(await this._checkAuth())) return;

        var data = new FormData();
        data.append("file", file);

        const resp = await fetch(`${endpoint}/profile/group-logo`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            body: data,
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async deleteGroupLogo(file) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/group-logo`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async uploadProfileCustomBackground(file) {
        if (!(await this._checkAuth())) return;

        var data = new FormData();
        data.append("file", file);

        const resp = await fetch(`${endpoint}/profile/custom-background`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            body: data,
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async deleteProfileCustomBackground() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/custom-background`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async deleteProfilePhoto() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/photo`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async uploadProfileMenu(file) {
        if (!(await this._checkAuth())) return;

        var data = new FormData();
        data.append("file", file);

        const resp = await fetch(`${endpoint}/profile/menu`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            body: data,
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async deleteProfileMenu() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/menu`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async setProfileMenuLink(link) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/menu/link`, {
            method: "PUT",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ link }),
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async restoreProfile() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/restore`, {
            method: "POST",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async restoreClearProfile() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/restore-clear`, {
            method: "POST",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async hardDeleteProfile() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/hard-delete`, {
            method: "POST",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async registration(email, password) {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/registration`, {
            method: "POST",
            credentials: "include",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async cancelRegistration() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/registration`, {
            method: "DELETE",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }

    static async resendEmailConfirmation() {
        if (!(await this._checkAuth())) return;

        const resp = await fetch(`${endpoint}/profile/resend-email-confirmation`, {
            method: "POST",
            credentials: "include",
            redirect: "follow",
        });

        if (await this._checkStatus(resp)) return await resp.json();
    }
}
