import { makeAutoObservable, toJS } from 'mobx';

class ModalsStore {
    modals = {
        order: false,
        signin: false,
        signup: false,
        forgot: false,
        cropper: false
    }

    data = {}


    constructor() {
        makeAutoObservable(this)
    }

    setOpened = (modal, value, data = {}) => {
        const modals = toJS(this.modals)
        Object.keys(modals).forEach(key => {
            if (key === modal) {
                modals[key] = value
            } else {
                modals[key] = false
            }
        })
        this.modals = modals
        this.data = data
    }

    setOpenedOrder = (open) => {
        this.setOpened('order', open)
    }

    setOpenedSignin = (open) => {
        this.setOpened('signin', open)
    }

    setOpenedCropper = (open, url = '') => {
        this.setOpened('cropper', open, { url })
    }


    setOpenedSignup = (open) => {
        this.setOpened('signup', open)
    }

    setOpenedForgotPassword = (open) => {
        this.setOpened('forgot', open)
    }
}

export const modalsStore = new ModalsStore()