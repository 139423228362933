export default function serviceParser(link) {
    if (!link) return 'site'

    link = link.value ?? link

    if (link.includes('instagram.com')) {
        return 'Instagram'
    }

    if (link.includes('play.google.com') && link.includes('store')) {
        return 'GooglePlay'
    }

    if (link.includes('microsoft.com')) {
        return 'MicrosoftStore'
    }

    if (link.includes('besplatka.ua')) {
        return 'Besplatka'
    }

    if (link.includes('olx.ua')) {
        return 'OLX'
    }

    if (link.includes('crafta.ua')) {
        return 'Crafta'
    }

    if (link.includes('prom.ua') || link.includes('deal.by') || link.includes('satu.kz') || link.includes('tiu.ru')) {
        return 'Prom'
    }

    if (link.includes('bigl.ua')) {
        return 'Bigl'
    }

    if (link.includes('izi.ua')) {
        return 'IZI'
    }

    if (link.includes('kabanchik.ua')) {
        return 'Kabanchik'
    }

    if (link.includes('shafa.ua')) {
        return 'Shafa'
    }

    if (link.includes('rozetka.com.ua')) {
        return 'Rozetka'
    }

    if (link.includes('etsy.com')) {
        return 'Etsy'
    }

    if (link.includes('amazon.com')) {
        return 'Amazon'
    }

    if (link.includes('joom.com')) {
        return 'Joom'
    }

    if (link.includes('ebay.com')) {
        return 'eBay'
    }

    if (link.includes('avito.ru')) {
        return 'Avito'
    }

    if (link.includes('airbnb.ru')) {
        return 'Airbnb'
    }

    if (link.includes('booking.com')) {
        return 'Booking'
    }

    if ((link.includes('google') || link.includes('goo.gl')) && link.includes('maps')) {
        return 'GoogleMaps'
    }

    if (link.includes('mega.nz')) {
        return 'MEGA'
    }

    if (link.includes('onedrive.com') || link.includes('onedrive.live.com') || link.includes('1drv.ms')) {
        return 'OneDrive'
    }

    if (link.includes('dropbox.com')) {
        return 'Dropbox'
    }

    if (link.includes('drive.google.com')) {
        return 'GoogleDrive'
    }

    if (link.includes('fex.net')) {
        return 'FEX'
    }

    if (link.includes('disk.yandex.ua') || link.includes('yadi.sk')) {
        return 'YandexDisk'
    }

    if (link.includes('twitch.tv')) {
        return 'Twitch'
    }

    if (link.includes('pscp.tv')) {
        return 'Periscope'
    }

    if (link.includes('tiktok.com')) {
        return 'TikTok'
    }

    if (link.includes('likee.com')) {
        return 'Likee'
    }

    if ((link.includes('youtube.com') || link.includes('youtu.be')) && !link.includes('music') && !link.includes('shorts')) {
        return 'YouTube'
    }

    if (link.includes('vimeo.com')) {
        return 'Vimeo'
    }

    if (link.includes('apps.apple.com')) {
        return 'AppStore'
    }

    if (link.includes('itunes.apple.com')) {
        return 'iTunes'
    }

    if (link.includes('music.apple.com')) {
        return 'AppleMusic'
    }

    if (link.includes('books.apple.com')) {
        return 'AppleBooks'
    }

    if (link.includes('tv.apple.com')) {
        return 'AppleTV'
    }

    if (link.includes('icloud.com')) {
        return 'iCloud'
    }

    if (link.includes('google.com') && link.includes('music')) {
        return 'GooglePlayMusic'
    }

    if (link.includes('music.yandex.ru')) {
        return 'YandexMusic'
    }

    if (link.includes('music.youtube.com')) {
        return 'YouTubeMusic'
    }

    if (link.includes('soundcloud.com')) {
        return 'SoundCloud'
    }

    if (link.includes('spotify.com')) {
        return 'Spotify'
    }

    if (link.includes('boom.ru')) {
        return 'BOOM'
    }

    if (link.includes('tidal.com')) {
        return 'Tidal'
    }

    if (link.includes('deezer.com')) {
        return 'Deezer'
    }

    if (link.includes('vk.com')) {
        return 'VK'
    }

    if (link.includes('ok.ru')) {
        return 'OK'
    }

    if (link.includes('reddit.com')) {
        return 'Reddit'
    }

    if (link.includes('ask.fm')) {
        return 'ASKfm'
    }

    if (link.includes('tumblr.com')) {
        return 'Tumblr'
    }

    if (link.includes('facebook.com')) {
        return 'Facebook'
    }

    if (link.includes('twitter.com') || link.includes('x.com')) {
        return 'X (Twitter)'
    }

    if (link.includes('.huawei.com')) {
        return 'AppGallery'
    }

    if (link.includes('of.com') || link.includes('of.tv') || link.includes('onlyfans.com')) {
        return 'OnlyFans'
    }

    if (link.includes('youtube.com/shorts')) {
        return 'YouTube Shorts'
    }

    if (link.includes('wikimapia.org')) {
        return 'Wikimapia'
    }

    if (link.includes('bing.com/maps')) {
        return 'Bing Maps'
    }

    if (link.includes('.waze.com')) {
        return 'Waze'
    }

    if (link.includes('tripadvisor.com')) {
        return 'Tripadvisor'
    }

    if (link.includes('yelp.com')) {
        return 'Yelp'
    }

    if (link.includes('linkedin.com')) {
        return 'LinkedIn'
    }

    if (link.includes('livejournal.com')) {
        return 'Livejournal'
    }

    if (link.includes('pinterest.com') || link.includes('pin.it')) {
        return 'Pinterest'
    }

    if (link.includes('behance.net')) {
        return 'Behance'
    }

    if (link.includes('dribbble.com')) {
        return 'Dribbble'
    }

    if (link.includes('deviantart.com') || link.includes('fav.me')) {
        return 'DeviantArt'
    }

    if (link.includes('500px.com')) {
        return '500px'
    }

    if (link.includes('flickr.com') || link.includes('flic.kr')) {
        return 'Flickr'
    }

    if (link.includes('artstation.com')) {
        return 'ArtStation'
    }

    if (link.includes('github.com') || link.includes('github.io')) {
        return 'GitHub'
    }

    if (link.includes('stackoverflow.com')) {
        return 'StackOverflow'
    }

    return 'site'
}
