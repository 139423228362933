import { messengersList } from "./messengersList";
import { paymentsList } from "./paymentsList";
import serviceParser from "./serviceParser.ts";

export const limits = {
    emails: 6,
    phones: 6,
    waiter_phones: 6,
    sites: 3
};

export const limitsByName = {
    payments: 3,
    messengers: 3,
    waiter_messengers: 3,
    banks: 3,
    social_networks: 3,
}

export function checkLimit(type, values, name, onErr) {
    if (!values) return true
    if (!values.length) return true
    if (!type) return true

    if (limits[type]) {
        return values.length < limits[type]
    }

    if (limitsByName[type]) {
        if (!name) return true

        const limit = limitsByName[type]

        const services = {}
        values.forEach(value => {
            const service = value.name
            if (!services[service]) services[service] = 0
            !isNew(value) && services[service]++
        })

        const overLimit = Object.entries(services).find(([service, count]) => count >= limit && service !== 'site' && (name === '*' || service === name))
        if (overLimit) {
            onErr && onErr(...overLimit)
            return false
        } else {
            return true
        }
    }
}

export function validateService(value, arr, field) {
    if (isNew(value)) return false
    if (!validateURL(value)) return false

    const services = {}
    arr.forEach(value => {
        if (!value.name) value.name = serviceParser(value.value)
        if (value.name === 'site') return
        let service = value.name
        if (!services[service]) services[service] = 0
        services[service]++
    })

    const names = Object.keys(services)
    for (let i = 0; i < names.length; i++) {
        const name = names[i]
        const limit = limitsByName[field]
        if (!limit) continue

        if (services[name] > limit) {
            const i = arr.findIndex(v => v.id === value.id)
            if (i === arr.length - 1) return 'service-error__limit'
        }
    }

    return true
}

export function validateEmail(email) {
    if (isNew(email)) return false

    email = email.value ? email.value : email

    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export function validateName(name) {
    if (isNew(name)) return false

    return /\w{0,64}/gm.test(name)
}

export function validateInfo(info) {
    if (isNew(info)) return false

    return /\w*/.test(info)
}

export function validatePhone(phone) {
    if (isNew(phone)) return false

    phone = phone.value ? phone.value : phone

    if (!phone || phone === '+') return false

    if (!/^\+?\d+$/.test(phone)) return 'phone-error__only_digits'

    if (/^\+?380.*/.test(phone) && !/^\+?380\d{9}$/.test(phone)) return 'phone-error__ukr_digits_count'

    if (phone.length > 50) return 'phone-error__digits_count'

    return true
}

export function validatePassword(password) {
    if (isNew(password)) return false

    return password.length > 0
}

export function validateDomain(domain) {
    return new RegExp(`^${getCheckDomainRegexp().source}$`).test(domain)
}

export function validateURL(url) {
    if (isNew(url)) return false

    try {
        new URL(url.value ? url.value : url);
    } catch {
        return false;
    }

    return true;

}

const banksChecks = {
    "Privat24": '(next\\.|www\\.)?privat24\\.ua',
    "Monobank": '(api\\.|send\\.|www\\.)?monobank(\\.com)?\\.ua',
    "A-Bank": '(pay\\.a-bank|a-bank|inst\\d+\\.paylink)\\.com\\.ua'
}

export function validateBank(bank) {
    if (isNew(bank.value)) return false

    const domainCheck = banksChecks[bank.name]
    if (!domainCheck) return false

    return getCheckURLRegexp(domainCheck).test(bank.value)
}

export function validateMessenger(messenger) {
    if (isNew(messenger.value)) return false

    const messInfo = messengersList.find(m => m.name === messenger.name)
    if (!messInfo) return false
    return messInfo.validate(messenger.value)
}

export function validatePayment(data) {
    if (isNew(data.value)) return false

    const info = paymentsList.find(m => m.name === data.name)
    if (!info) return false
    return info.validate(data.value)
}

export function validateSite(site) {
    if (isNew(site.value)) return false

    return validateURL(site.value)
}

export function validateProfileURL(url) {
    if (isNew(url)) return false

    return new RegExp('^[a-z0-9\\-_]+$').test(url)
}

export function validateCustomButton(custom_button) {
    const { title, value, icon, link } = custom_button
    const warnings = {
        title: false,
        value: false,
        emoji: false,
        link: false
    }

    let valid = true

    const isEmpty = value => !value

    if (isEmpty(title) && isEmpty(value) && isEmpty(link) && isEmpty(icon)) return false

    if (isNew(title) && isNew(value) && isNew(link) && isNew(icon)) return false

    if (title.length > 400) {
        warnings.title = 'custom-button-title-error__too_long'
        valid = false
    }

    if (value.length > 400) {
        warnings.value = 'custom-button-title-error__too_long'
        valid = false
    }

    if (link && !isNew(link) && !validateURL(link)) {
        warnings.link = true
        valid = false
    }

    if (valid) return true

    return warnings
}

export function validateCustomization(customization) {
    const { logo_link, logo, background, disable_created_by, ...colors } = customization
    const warnings = {
        logo_link: false,
    }

    let valid = true

    const isEmpty = value => !value

    Object.keys(colors).forEach(key => {
        const value = colors[key]
        if (!isEmpty(value) && !isNew(value) && !validateHex(value)) {
            warnings[key] = true
            valid = false
        } else {
            warnings[key] = false
        }
    })

    if (!isEmpty(logo_link) && !isNew(logo_link) && !validateURL(logo_link)) {
        warnings.logo_link = true
        valid = false
    }

    if (valid) return true

    return warnings
}

export function validateGroupLogo(groupLogo) {
    const { link } = groupLogo
    const warnings = {
        link: false,
    }

    let valid = true

    const isEmpty = value => !value

    if (!isEmpty(link) && !isNew(link) && !validateURL(link)) {
        warnings.link = true
        valid = false
    }

    if (valid) return true

    return warnings
}

export function getCheckDomainRegexp() {
    return new RegExp('(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}(\\:[0-9]{1,5})*', 'i')
}

export function getCheckURLRegexp(domain) {
    const pattern = '^(https?:\\/\\/)' + // protocol
        domain +
        '(\\/[-A-z\\d%_.,~+\\:@]*)*' + // path
        '(\\?[/\\:;&A-z\\d%_,.~+=-]*)?' + // query string 
        '(\\#[-A-z\\d_\\:]*)?$'

    return new RegExp(pattern, 'i')
}

export function validateHex(hex) {
    return new RegExp('^#(\\p{Hex_Digit}{3}|\\p{Hex_Digit}{6}|\\p{Hex_Digit}{8})$', 'ug').test(hex)
}

export const newValuePlaceholder = '__new__'

export function isNew(value) {
    if (value && value.value) value = value.value
    return value === newValuePlaceholder
}