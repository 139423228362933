import { API, host } from 'api/utils'

const endpoint = `${host}/api/v1`

export default class SettingsAPI extends API {
    static async getSettings() {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow',
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async setType(type) {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/type`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ type }),
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async setDisableAutoSave(disable_auto_save) {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/disable-auto-save`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ disable_auto_save }),
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async changeEmail(email) {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/email`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })

        if (await this._checkStatus(resp)) {
            if (resp.status === 409) {
                throw new Error("error__user-already-exists")
            }
            return await resp.json()
        }
    }

    static async changePassword(old_password, new_password) {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/password`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ old_password, new_password }),
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }

    static async deleteProfile() {
        if (!(await this._checkAuth())) return

        const resp = await fetch(`${endpoint}/settings/profile`, {
            method: 'DELETE',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (await this._checkStatus(resp)) return await resp.json()
    }
}
