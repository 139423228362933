import { injectStores } from "@mobx-devtools/tools";
import isDebug from "utils/isDebug";
import { adminStore } from "./adminStore";
import { modalsStore } from "./modalsStore";
import { orderStore } from "./orderStore";
import { profileStore } from "./profileStore";
import { settingsStore } from "./settingsStore";

if (isDebug()) {
    injectStores({ settingsStore, profileStore, modalsStore, orderStore, adminStore })
}