import { round } from "./helpers"

export const rowHeight = 60
export const gridWidht = 3

export function sortContacts(profile, warnings) {
    const result = []

    function filterWarnings(list, w) {
        if (!list.length) return []
        return list.filter((_, i) => !(w && w[i]))
    }

    function filterTitled(list, returnTitled) {
        if (!list.length) return []
        return list.filter(el => {
            if (returnTitled) {
                return el.title || (el.type === 'custom_button' && el.value)
            } else {
                return !el.title && !(el.type === 'custom_button' && el.value)
            }
        })
    }

    function addItems(arr, titled, type) {
        const list = filterWarnings(profile[type], warnings[type])
            .map(el => Object.assign(el, { type }))

        arr.push(...filterTitled(list, titled).map(el => {
            if (!el.id) {
                el.id = crypto.randomUUID()
            }
            return el
        }))
    }

    const order = [
        'phones', 'emails', 'sites',
        'messengers', 'banks', 'payments',
        'social_networks', 'custom_buttons'
    ]

    if (profile.type === 30) {
        order.push('waiter_phones', 'waiter_messengers')
    }

    const titled = []

    order.forEach(el => {
        addItems(titled, true, el)
        addItems(result, false, el)
    })
    result.push(...titled)

    if (!profile.default_card_items || !profile.default_card_items.length) {
        profile.default_card_items = [
            { id: 'add-to-contacts', type: 'add-to-contacts' }
        ]
    }
    if (profile.type === 30 && !profile.default_card_items.find(el => el.type === 'menu')) {
        profile.default_card_items.push({
            id: 'menu', type: 'menu', href: profile.menu
        })
    }
    result.push(...profile.default_card_items)

    return result
}

export function updateLayout(contacts, layout, prevLayout) {
    if (!contacts || !contacts.length) return []

    const getSize = (el) => {
        const h = el.type === 'add-to-contacts' || el.type === 'menu' ? round(42 / rowHeight) : round(60 / rowHeight)
        let w = el.is_with_title || el.title || el.type === 'add-to-contacts' || el.type === 'menu' ? gridWidht : 1
        if (el.type === 'custom_buttons' && el.value) w = gridWidht
        return { h, w }
    }

    let col = 0
    let row = 0

    layout = layout ?? []
    const contMap = new Map(contacts.map(el => [el.id, el]))
    layout = layout.filter(el => contMap.has(el.i))
    const layMap = new Map(layout && layout.map(el => [el.i, el]))
    const prevLayMap = new Map(prevLayout && prevLayout.map(el => [el.i, el]))


    //console.debug('===============================================')
    const changedWidth = []
    const changedHeight = []

    contacts.forEach(contact => {
        let dataGrid = layMap.get(contact.id)
        let prevDataGrid = prevLayMap.get(contact.id)
        const size = getSize(contact)

        if (!dataGrid) {
            if (col + size.w > gridWidht) {
                col = 0;
                row += size.h;
            }

            const next = size => {
                col += size.w;
                if (col > 2) {
                    col = 0;
                    row += size.h;
                }
            }

            for (
                let collisions = getCollisions(layout, { x: col, y: row, ...size });
                collisions.length;
                collisions = getCollisions(layout, { x: col, y: row, ...size })
            ) {
                collisions.forEach(collision => {
                    next({
                        w: size.w,
                        h: collision.h
                    })
                })

            }

            layout.push({ x: col, y: row, i: contact.id, ...size })

            next(size)
        } else {
            const { w, h } = dataGrid

            if (prevLayMap.size) {
                if (w !== prevDataGrid?.w ?? w) {
                    changedWidth.push(dataGrid)
                }

                if (h !== prevDataGrid?.h ?? h) {
                    changedHeight.push({
                        el: dataGrid,
                        diff: h - (prevDataGrid?.h ?? h)
                    })
                }
            }
        }
    });

    changedHeight.forEach(({ el, diff }) => {
        const { y, i } = el

        const sorted = layout.filter(el => el.y >= y).sort((a, b) => a.y - b.y)
        const changedIndex = sorted.findIndex(el => el.i === i)

        for (let i = changedIndex + 1; i < sorted.length; i++) {
            const item = sorted[i]
            const index = layout.findIndex(el => el.i === item.i)
            layout[index].y += diff
        }
    })

    changedWidth.forEach(changedEl => {
        const { w, h } = changedEl

        let changes = {}
        if (w > 1) {
            let maxRow = 0
            let maxGridEl = null
            layout.forEach(item => {
                if (item.y > maxRow) {
                    maxRow = item.y
                    maxGridEl = item
                }
            })

            changes = { y: maxRow + maxGridEl?.h ?? 0, x: 0 }
        } else {
            let row = 0, col = 0
            const size = { w, h }

            const next = size => {
                col += size.w;
                if (col > 2) {
                    col = 0;
                    row += size.h;
                }
            }

            for (
                let collisions = getCollisions(layout, { x: col, y: row, ...size });
                collisions.length;
                collisions = getCollisions(layout, { x: col, y: row, ...size })
            ) {
                collisions.forEach(collision => {
                    next({
                        w: size.w,
                        h: collision.h
                    })
                })

            }

            changes = { x: col, y: row }
        }


        Object.assign(changedEl, changes)
    })

    clearGaps(layout)
    return layout
}

export function getCollisions(layout, item) {
    console.debug('getCollisions', layout, item)
    return layout.filter(el => checkCollission(item, el))
}

export function clearGaps(layout) {
    let currentY = 0;
    const sorted = layout.sort((a, b) => a.y - b.y)
    sorted.forEach((dataGrid, index) => {
        dataGrid.y = round(dataGrid.y)
        dataGrid.h = round(dataGrid.h)

        layout[index] = dataGrid;
    });

    const rows = {}
    sorted.forEach(el => {
        if (!rows[el.y]) {
            rows[el.y] = []
        }
        rows[el.y].push(el)
    })

    let isGapFound = false

    Object.keys(rows).forEach(row => {
        const y = rows[row][0].y
        const h = rows[row][0].h

        if (y > currentY) {
            console.log('currentY-y', currentY, y)
            rows[row].forEach(el => {
                const index = layout.findIndex(l => l.i === el.i)
                layout[index].y = currentY
            })
            isGapFound = true
        }

        currentY = round(y + h);
    })

    if (isGapFound) clearGaps(layout)

    console.debug('layout', layout, Math.round(currentY * 100) / 100)
}

export function moveDown(layout, item, h) {
    const i = layout.findIndex(l => l.i === item.i)
    layout[i].y = layout[i].y + h
    layout = layout.filter(l => l.i !== item.i)
    const colissions = getCollisions(layout, item);
    console.debug('moveDown', item, h, colissions, layout)
    if (colissions.length) {
        colissions.forEach(c => moveDown(layout, c, h))
    }
}

export function moveUp(layout, item, h) {
    const newY = item.y - h
    if (newY < 0) return false
    const colissions = getCollisions(layout.filter(l => l.i !== item.i), { ...item, y: newY });
    console.debug('moveUp', item, h, newY, colissions, layout)
    if (colissions.length) return false

    const i = layout.findIndex(l => l.i === item.i)
    layout[i].y = newY

    return true
}

export function moveIcon(layout, item, filterFn) {
    const index = layout.findIndex(l => l.i === item.i)

    console.debug('moveIcon', item, layout)

    const hasPlaceLeft = item.x !== 0 && !layout.find(el => el.x === item.x - 1 && el.y === item.y && (!filterFn || filterFn(el)))
    if (hasPlaceLeft) {
        layout[index].x--
        console.debug('moveIcon left', layout[index], hasPlaceLeft)
        return
    }

    moveRight(layout, item)

    console.debug('movedIcon', item, layout)
}

function moveRight(layout, item) {
    const index = layout.findIndex(l => l.i === item.i)

    console.debug('moveRight0', item)

    if (layout[index].x + 1 >= gridWidht) {
        layout[index].x = 0;
        layout[index].y += 1;

        const collisions = getCollisions(layout, item)
        if (collisions.length) {
            collisions.forEach(c => moveRight(layout, c))
        }
        return
    }

    if (item.w === gridWidht) {
        moveDown(layout, item, 1)
        return
    }

    layout[index].x++

    const sortedElements = layout.sort((a, b) => Math.hypot(a.x, a.y) - Math.hypot(b.x, b.y))

    console.debug('moveRight1', item, sortedElements)

    const targetIndex = sortedElements.findIndex(
        el => el.i === item.i
    );

    const filtered = sortedElements[targetIndex - 1] ? sortedElements.slice(targetIndex - 1) : []

    console.debug('moveRight', item, filtered)

    let hasCollision;
    do {
        hasCollision = false;
        for (let i = 0; i < filtered.length; i++) {
            const elem = filtered[i]
            if (elem.i !== item.i && checkCollission(item, elem)) {
                hasCollision = true
                moveRight(layout, elem)
            }
            console.debug('moveRight3', hasCollision, elem, item)
        }
    } while (hasCollision)
}

function checkCollission(a, b) {
    if (a.i === b.i) return
    if (a.x + a.w <= b.x) return
    if (a.x >= b.x + b.w) return
    if (a.y + (a.h / 1.1) <= b.y) return
    if (a.y >= b.y + (b.h / 1.1)) return
    return true
}