import NovaposhtaAPI from "api/novaposhta";
import OrderAPI from "api/order";
import ProductsAPI from "api/products";
import { profileStore } from "stores/profileStore";

import i18n from "i18next";
import { onCheckout, onPurchase } from "utils/googleAnalytics";
import { validateEmail, validateName, validatePhone } from "utils/validations";
import { hasRibbon, isAddressShipping, isLegal, isNovaPoshta } from "../utils/helpers";
import { modalsStore } from "./modalsStore";

const { makeAutoObservable, toJS, reaction } = require("mobx");

const initOrder = {
    productID: null,
    phone: '+380',
    email: '',
    count: 1,

    accessoryID: 1,
    printTypeID: 1,
    colorID: 1,
    ribbonID: 2,

    createPrintDesign: true,
    enablePrintDesignCount: false,
    printDesignCount: 1,

    enableCustomPageDesign: false,
    enableCustomPageDesignCount: false,
    customPageDesignCount: 1,

    shipping: 'novaposhta',
    shippingType: 'department',
    payment: 'liqpay',
    address: {
        street: {},
        house: '',
        flat: ''
    },
    recipient: 'personal',
    city: {},
    department: {},
    ownership: '',
    edrpou: '',
    organization: '',
    firstname: '',
    lastname: '',
    middleName: '',
    comment: ''
}

const initStatus = {
    failed: false,
    ok: false,
    error: '',
    loading: false
}

const initWarnings = {
    address: {}
}

const initAmount = {
    total: 0,
    count: 0,
    printTypeDesign: 0,
    pageDesign: 0
}

class OrderStore {
    isLoading = true

    order = Object.assign({}, initOrder)
    status = Object.assign({}, initStatus)
    warnings = Object.assign({}, initWarnings)

    base = null
    products = []
    product = null
    accessory = null
    disableLocation = false

    ownerships = []

    amount = Object.assign({}, initAmount)

    reactions = []

    constructor() {
        makeAutoObservable(this)
        i18n.on('languageChanged', this.onLanguageChanged)
        this.updateBase()
    }

    onLanguageChanged = () => {
        this.changeURL()
        this.updateBase()
    }

    updateBase = async () => {
        this.base = await ProductsAPI.getProductsBase()
        this.isLoading = false
    }

    openModal = async (orderParams, disableLocation) => {
        if (orderParams) {
            await this.init(orderParams, disableLocation)
        }

        onCheckout(this.order, this.product, this.amount)

        modalsStore.setOpenedOrder(true)
    }

    init = async (orderParams, disableLocation) => {
        this.disableLocation = disableLocation
        let { accessoryID } = orderParams
        if (!accessoryID) accessoryID = 1

        if (this.order.productID && accessoryID === this.order.accessoryID) return

        const { profile } = profileStore

        this.unregisterReactions()

        const order = Object.assign({}, toJS(this.order), orderParams)

        order.name = profile ? profile.name : initOrder.name
        order.phone = profile && profile.phones.length ? (profile.phones[0].value ? profile.phones[0].value : profile.phones[0]) : initOrder.phone
        order.email = profile && profile.emails.length ? (profile.emails[0].value ? profile.emails[0].value : profile.emails[0]) : initOrder.email

        this.order = order

        this.registerReactions()

        await this.updateProductID()
    }

    calcAmount = () => {
        const {
            count, createPrintDesign, enablePrintDesignCount,
            printDesignCount, enableCustomPageDesign,
            enableCustomPageDesignCount, customPageDesignCount,
            printTypeID, payment
        } = this.order

        if (!this.base) return 0

        const { print_design_price, page_design_price } = this.base

        if (!this.product) return 0

        let amount = Object.assign({}, initAmount)

        amount.count = ((this.product.price * count) / 100)

        const isIndividualDesign = printTypeID === 2

        if (isIndividualDesign && createPrintDesign) {
            const { first, subsequent } = print_design_price

            let designCount = count
            if (enablePrintDesignCount) {
                designCount = printDesignCount
            }

            amount.printTypeDesign = Math.ceil(first + (designCount - 1) * subsequent) / 100
        }

        if (enableCustomPageDesign) {
            const { first, min, discount } = page_design_price
            const discountFloat = discount / 100

            let designCount = count
            if (enableCustomPageDesignCount) {
                designCount = customPageDesignCount
            }

            amount.pageDesign = first
            let price = first
            for (let i = 0; i < designCount - 1; i++) {
                price = Math.ceil(price - (price * discountFloat / 100))

                if (price < min) price = min

                amount.pageDesign += price
            }

            if (amount.pageDesign > 100000) {
                amount.pageDesign = Math.ceil(amount.pageDesign / 100 / 100) * 100
            } else {
                amount.pageDesign /= 100
            }
        }

        amount.total = amount.count + amount.printTypeDesign + amount.pageDesign

        this.amount = amount

        if ((printTypeID === 2 || enableCustomPageDesign) && payment === 'upon-receipt') {
            this.order.payment = 'liqpay'
        }
    }

    updateProductID = async (force) => {
        const base = this.base
        if (!base) return

        const prevAccessoryID = this.accessory && this.accessory.id

        const accessory = base.accessories.find(a => a.id === this.order.accessoryID)
        if (!accessory) return
        this.accessory = accessory

        if (this.order.colorID && !this.accessory.colors.includes(this.order.colorID)) {
            this.order.colorID = this.accessory.colors[0]
        }

        if (this.order.colorID === 0 && this.order.printTypeID === 1) {
            this.order.printTypeID = 2
        }

        if (this.order.colorID > 0 && this.order.printTypeID === 2) {
            this.order.printTypeID = 1
        }

        let productID = this.order.accessoryID.toString()
        productID += this.order.printTypeID.toString()
        productID += this.order.colorID.toString()
        productID += hasRibbon(this.order.accessoryID) ? this.order.ribbonID.toString() : ''
        productID += base.id_localization.toString()

        if (this.order.productID === productID) return

        if (this.order.accessoryID !== prevAccessoryID || force) {
            this.products = await ProductsAPI.getProducts(this.order.accessoryID)
        }

        const product = this.products.find(p => p.id === productID)
        if (!product) return
        this.product = product

        this.order.productID = productID
        if (!this.disableLocation) this.changeURL()
    }

    changeURL = () => {
        const { accessoryID, colorID, printTypeID, ribbonID } = this.order
        const lang = i18n.language
        const currentPath = window.location.pathname

        const accessoryObj = this.base.accessories.find(acc => acc.id === accessoryID)
        if (!accessoryObj) {
            return
        }

        const colorObj = this.base.colors.find(col => col.id === colorID)
        if (!colorObj) {
            return
        }

        const printTypeObj = this.base.print_types.find(pt => pt.id === printTypeID)
        if (!printTypeObj) {
            return
        }

        const ribbonObj = this.base.ribbons.find(r => r.id === ribbonID)
        if (!ribbonObj) {
            return
        }

        let url = `/products/${accessoryObj.path}/${lang}/${colorObj.path}/${printTypeObj.path}`
        if (hasRibbon(accessoryID)) {
            url += `/${ribbonObj.path}`
        }

        if (currentPath !== url) {
            window.history.replaceState(null, '', url)
        }
    }

    changePrintType = printTypeID => {
        if (this.order.printTypeID === printTypeID) return

        const changes = {
            printTypeID,
            colorID: this.accessory.colors[0]
        }

        if (printTypeID === 2) {
            changes.colorID = 0
        }

        Object.assign(this.order, changes)
    }

    changeOrder = (field, value, order = this.order, warnings = this.warnings) => {
        if (field.includes('.')) {
            const split = field.split('.')
            const first = split.splice(0, 1)[0]

            order = order[first]
            warnings = warnings[first]
            field = split.join('.')

            this.changeOrder(field, value, order, warnings)
            return
        }

        if (!field || order[field] === value) return

        warnings[field] = false
        order[field] = value
    }

    setCount = count => {
        if (isNaN(count) || !count || count > 1000) {
            return
        }
        this.count = count
    }

    validation = () => {
        let valid = true
        const warnings = Object.assign({}, initWarnings)

        const { firstname, lastname,
            phone, email, city,
            department, ownership, edrpou,
            organization, recipient, shipping,
            address, shippingType } = this.order

        let phoneErr = validatePhone(phone)
        if (!phoneErr || typeof (phoneErr) == 'string') {
            valid = false
            warnings.phone = phoneErr
        }

        // TODO: refactor
        phoneErr = this.validatePhone(phone)
        if (!phoneErr || typeof (phoneErr) == 'string') {
            valid = false
            warnings.phone = phoneErr
        }

        if (!email || !validateEmail(email)) {
            valid = false
            warnings.email = true
        }

        if (!firstname || !validateName(firstname)) {
            valid = false
            warnings.firstname = true
        }

        if (!lastname || !validateName(lastname)) {
            valid = false
            warnings.lastname = true
        }

        if (isNovaPoshta(shipping)) {
            if (isAddressShipping(shippingType)) {
                if (!address.street.id) {
                    valid = false
                    warnings.address.street = true
                }
            } else {
                if (!city.id) {
                    valid = false
                    warnings.city = true
                }

                if (!department.id) {
                    valid = false
                    warnings.department = true
                }
            }


            if (isLegal(recipient)) {
                if (!ownership) {
                    valid = false
                    warnings.ownership = true
                }

                if (!edrpou) {
                    valid = false
                    warnings.edrpou = true
                }

                if (!organization) {
                    valid = false
                    warnings.organization = true
                }
            }
        }

        this.warnings = warnings

        return valid

    }

    clear = () => {
        const productID = this.order.productID
        this.order = Object.assign({}, initOrder)
        this.status = Object.assign({}, initStatus)
        this.warnings = Object.assign({}, initWarnings)
        this.order.productID = productID
    }

    submit = async () => {
        if (this.validation()) {
            this.status.loading = true

            const order = toJS(this.order)
            order.productName = this.products.find(item => item.id === order.productID).title
            order.middleName = order.middleName.replace('i', 'і').trim()
            order.lastname = order.lastname.replace('i', 'і').trim()
            order.firstname = order.firstname.replace('i', 'і').trim()


            try {
                const { success, orderID } = await OrderAPI.create(order)
                if (!success) {
                    throw new Error('error__unknown')
                }

                onPurchase(orderID, this.order, this.product, this.amount)

                if (order.payment === 'liqpay') {
                    window.location.href = `/order/${orderID}/pay`
                    return
                }

                this.clear()
                this.status.ok = true
            } catch (e) {
                this.status.error = e.message
            }

            this.status.loading = false
        }
    }

    loadOwnerships = async () => {
        if (!this.ownerships.length) {
            this.ownerships = await NovaposhtaAPI.getOwnerships()
        }
    }

    unregisterReactions() {
        this.reactions.forEach(r => r())
        this.reactions = []
    }

    registerReactions() {
        const reactions = [
            {
                fields: [
                    'createPrintDesign', 'enablePrintDesignCount', 'printDesignCount',
                    'enableCustomPageDesign', 'enableCustomPageDesignCount', 'customPageDesignCount',
                    'productID', 'count'
                ],
                reaction: (value, r) => {
                    this.calcAmount()
                }
            },
            {
                fields: [
                    'accessoryID', 'printTypeID',
                    'colorID', 'ribbonID'
                ],
                reaction: (value, r) => {
                    this.updateProductID()
                }
            },
        ]

        reactions.forEach(r => {
            r.fields.forEach(f => {
                const react = reaction(
                    () => this.order[f],
                    r.reaction,
                )
                this.reactions.push(react)
            })
        })
        this.reactions.push(reaction(
            () => this.base,
            () => this.updateProductID(true)
        ))
    }

    // TODO: refactor
    validatePhone(phone) {
        if (phone[0] === '0' && phone.length !== 10) {
            return 'phone-error__ukr_digits_short_count'
        }

        if (phone[0] === '8' && phone.length !== 11) {
            return 'phone-error__ukr_digits_short2_count'
        }

        return true
    }

    openFinish(success) {
        this.status.ok = success
        this.status.failed = !success
        modalsStore.setOpenedOrder(true)
    }
}

export const orderStore = new OrderStore()
