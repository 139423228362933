import ProfileAPI from 'api/profile'
import SettingsAPI from 'api/settings'
import i18n from 'i18next'
import { makeAutoObservable, reaction } from 'mobx'
import { profileStore } from './profileStore'

class SettingsStore {
    isLoading = true
    settings = null
    reactions = []

    constructor() {
        makeAutoObservable(this)
    }

    load = () => {
        SettingsAPI.getSettings()
            .then(settings => {
                this.unregisterReactions()

                this.settings = settings

                this.isLoading = false

                this.registerReactions()
            })
            .catch(console.error)
    }

    unregisterReactions() {
        this.reactions.forEach(r => r())
        this.reactions = []
    }

    registerReactions() {
        this.reactions.push(
            reaction(
                () => this.settings.type,
                (value, r) => {
                    this.changeType().then(() => {
                        console.debug('changeType reaction')
                        profileStore.load(true)
                    })
                },
            ),
        )
    }

    toggleAutoSave = async () => {
        this.settings.disable_auto_save = !this.settings.disable_auto_save
        return await this.apiSaveCall(
            async () => {
                try {
                    await SettingsAPI.setDisableAutoSave(this.settings.disable_auto_save)
                    profileStore.setSetting('disable_auto_save', this.settings.disable_auto_save)
                } catch (err) {
                    this.settings.disable_auto_save = !this.settings.disable_auto_save
                    throw err
                }
            },
            i18n.t('save-notify__success'),
            i18n.t('save-notify__error'),
        )
    }

    changeType = async () => {
        return await this.apiSaveCall(
            async () => {
                return await SettingsAPI.setType(this.settings.type)
            },
            i18n.t('save-notify__change_type__success'),
            i18n.t('save-notify__change_type__error'),
        )
    }

    changeEmail = async () => {
        return await this.apiSaveCall(
            async () => {
                await SettingsAPI.changeEmail(this.settings.email)
                if (this.settings.loginIsNotEmail) delete this.settings.loginIsNotEmail
            },
            i18n.t('save-notify__change_email__success'),
            i18n.t('save-notify__change_email__error'),
        )
    }

    changePassword = async () => {
        return await this.apiSaveCall(
            async () => {
                return await SettingsAPI.changePassword(this.settings.old_password, this.settings.new_password)
            },
            i18n.t('save-notify__change_password__success'),
            i18n.t('save-notify__change_password__error'),
        )
    }

    deleteProfile = async () => {
        try {
            await SettingsAPI.deleteProfile()
            profileStore.profile['deleted_at'] = Math.round(Date.now() / 1000)
        } catch (err) {
            window.notify(`${i18n.t('delete-profile__error')}: ${i18n.t(err.message)}`, 'error')
            throw err
        }
    }

    async cancelRegistrationProfile() {
        try {
            await ProfileAPI.cancelRegistration()
            profileStore.updateProfile()
        } catch (err) {
            window.notify(`${i18n.t('cancel-register-profile__error')}: ${i18n.t(err.message)}`, 'error')
        }
    }

    async apiSaveCall(func, successText, errorPrefix) {
        try {
            const res = await func()
            window.notify(successText, 'success')
            return res
        } catch (err) {
            window.notify(`${errorPrefix}: ${i18n.t(err.message)}`, 'error')
        }
    }
}

export const settingsStore = new SettingsStore()