import { API, host } from 'api/utils'

const endpoint = `${host}/order`

export default class OrderAPI extends API {
    static async create(order) {
        const resp = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(order)
        })

        if (await this._checkStatus(resp)) {
            return await resp.json()
        }
    }
}