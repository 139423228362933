import loadable from "@loadable/component";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "../locale";
import "./App.scss";


const Card = loadable(() => import("components/pages/Card"));
const Landing = loadable(() => import("components/pages/Landing"));
const Profile = loadable(() => import("components/pages/Profile"));
const Settings = loadable(() => import("components/pages/Settings"));
const Content = loadable(() => import("components/pages/Content"));
const NotFound = loadable(() => import("components/pages/NotFound"));
const AdminPanel = loadable(() => import("components/pages/AdminPanel"));
const Login = loadable(() => import("components/pages/Login"));
const Maintenance = loadable(() => import("components/pages/Maintenance"));
const Product = loadable(() => import("components/pages/Products/Product"));

function App() {
    const { i18n } = useTranslation();
    const isAdmin = useRef(window ? window.location.hostname.includes('admin.') : false);

    useEffect(() => {
        if (!['ua', 'en'].includes(i18n.language)) {
            i18n.changeLanguage("ua");
        }
    }, []);

    if (isAdmin.current) {
        return <React.StrictMode>
            <Router>
                <AdminPanel base=""/>
            </Router>
        </React.StrictMode>
    }

    return (
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/404.html" element={<NotFound />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="/main" element={<Landing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Landing signup />} />
                    <Route path="/order" element={<Landing order />} />
                    <Route path="/password_changed" element={<Landing password_changed />} />

                    <Route path="/profile/settings" element={<Settings />} />
                    <Route path="/profile" element={<Profile />} />

                    <Route path="/about" element={<Content contentName="about" />} />
                    <Route path="/user-agreement" element={<Content contentName="user-agreement" />} />
                    <Route path="/privacy-policy" element={<Content contentName="privacy-policy" />} />
                    <Route path="/product-return" element={<Content contentName="product-return" />} />
                    <Route path="/delivery-and-payment" element={<Content contentName="delivery-and-payment" />} />

                    <Route path="/products/:accessory/:lang/:color/:printing/:ribbon" element={<Product />} />
                    <Route path="/products/:accessory/:lang/:color/:printing" element={<Product />} />
                    <Route path="/products/:accessory/:lang/:color" element={<Product />} />
                    <Route path="/products/:accessory/:lang" element={<Product />} />
                    <Route path="/products/:accessory" element={<Product />} />
                    <Route path="/products" element={<Maintenance />} />
                    <Route path="/__admin__" element={<AdminPanel />} />

                    <Route path="/card/:profileID" element={<Card />} />
                    <Route path="/:profileID" element={<Card />} />
                    <Route path="/" element={<Landing />} />
                </Routes>
            </Router>
        </React.StrictMode>
    );
}

export default App;
