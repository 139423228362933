import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Loading from "components/general/Loading";
import { setup } from 'goober';
import { configure } from "mobx";
import "normalize.css";
import React, { Suspense } from "react";
import { render } from "react-dom";

import "stores/__debug.js";
import isDebug from "utils/isDebug.js";
import App from "./app/App.tsx";


setup(React.createElement);


configure({
    enforceActions: "never",
})

if (!isDebug()) {
    Sentry.init({
        dsn: "https://3a02941b61f449e6a8540192124b1cf9@o4504532092715008.ingest.sentry.io/4504532099203072",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

// TODO: сменить фреймворк на preact и добавить react-snap
render(<Suspense fallback={<Loading />}>

    <App />

</Suspense>, document.getElementById("root"));